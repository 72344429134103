* {
  box-sizing: border-box;
}

.search-container {
  background: #f5f5f5;
  padding: 5px;
}

.search-container-inner {
  margin: 25px auto;
  width: 350px;
  position: relative;
}

.search-container input {
  padding: 8px;
  width: 100%;
}

.search-container .dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #eee;
  margin: 0;
  list-style: none;
  z-index: 1;
  padding: 5px 0;
  max-height: 320px;
  overflow: auto;
}

.search-container .dropdown li {
  padding: 5px 10px;
  cursor: pointer;
}

.search-container .dropdown li:hover {
  background: #d5d5d5;
}


.card {
  height: 100%;
  background: #f5f5f5;
}

.card:hover {
  background: #ddd;
}

.card-img-top {
  height: 250px;
  object-fit: cover;
}